import request from '@/utils/request'

const newsApi = {
  // get news info
  news: '/api/v1/rest/news',
  findByKeyword: '/api/v1/rest/news/search/findByKeyword',
  findByHouses_Id: '/api/v1/rest/news/search/findByHouseId'
}

export function news (parameter) {
  return request({
    url: newsApi.news + `?page=${parameter.page}&size=${parameter.size}&sort=${parameter.sort}`,
    method: 'get'
  })
}

export function addNews (parameter) {
  return request({
    url: newsApi.news,
    method: 'post',
    data: parameter
  })
}

export function editNews (parameter) {
  return request({
    url: newsApi.news + `/${parameter.id}`,
    method: 'patch',
    data: parameter
  })
}

export function delNews (parameter) {
  return request({
    url: newsApi.news + `/${parameter.id}`,
    method: 'delete'
  })
}

export function putNewsMedia (newsId, data) {
  return request({
    url: newsApi.news + '/' + newsId + '/media',
    method: 'put',
    headers: {
      'Content-Type': 'text/uri-list'
    },
    data
  })
}

export function getNewsMedia (newsId) {
  return request({
    url: newsApi.news + '/' + newsId + '/media',
    method: 'get'
  })
}

export function findNews (parameter) {
  return request({
    url: newsApi.findByKeyword + `?keyword=${parameter.keyword}&page=${parameter.page}&size=${parameter.size}&sort=${parameter.sort}`,
    method: 'get'
  })
}

export function findByIdNews (parameter) {
  return request({
    url: newsApi.news + `/${parameter.id}`,
    method: 'get'
  })
}

export function inNews (parameter) {
  return request({
    url: newsApi.news + `/${parameter.id}/${parameter.name}`,
    method: 'get'
  })
}

export function addInNews (parameter) {
  return request({
    url: newsApi.news + `/${parameter.id}/${parameter.name}`,
    data: parameter
  })
}

export function editInNews (parameter) {
  return request({
    url: newsApi.news + `/${parameter.id}/${parameter.name}`,
    method: 'patch'
  })
}

export function findByHousesIdNews (parameter) {
  return request({
    url: newsApi.findByHouses_Id + `?houseId=${parameter.id}&page=${parameter.page}&size=${parameter.size}&sort=${parameter.sort}`,
    method: 'get'
  })
}
