import request from '@/utils/request'

const mediasApi = {
  // get medias info
  medias: '/api/v1/rest/medias',
  findByKeyword: '/api/v1/rest/medias/search/findByKeyword',
  findByHouses_Id: '/api/v1/rest/medias/search/findByHouseId'
}

export function medias (parameter) {
  return request({
    url: mediasApi.medias + `?page=${parameter.page}&size=${parameter.size}&sort=${parameter.sort}`,
    method: 'get'
  })
}

export function addMedias (parameter) {
  return request({
    url: mediasApi.medias,
    method: 'post',
    data: parameter
  })
}

export function saveMedias (data) {
  return request({
    url: mediasApi.medias,
    method: 'post',
    data
  })
}

export function editMedias (id, data) {
  return request({
    url: mediasApi.medias + `/${id}`,
    method: 'patch',
    data
  })
}

export function getMedia (id) {
  return request({
    url: mediasApi.medias + `/${id}`,
    method: 'get'
  })
}

export function delMedias (parameter) {
  return request({
    url: mediasApi.medias + `/${parameter.id}`,
    method: 'delete'
  })
}

export function findMedias (parameter) {
  return request({
    url: mediasApi.findByKeyword + `?keyword=${parameter.keyword}&page=${parameter.page}&size=${parameter.size}&sort=${parameter.sort}`,
    method: 'get'
  })
}

export function findByIdMedias (parameter) {
  return request({
    url: mediasApi.medias + `/${parameter.id}`,
    method: 'get'
  })
}

export function inMedias (parameter) {
  return request({
    url: mediasApi.medias + `/${parameter.id}/${parameter.name}`,
    method: 'get'
  })
}

export function addInMedias (parameter) {
  return request({
    url: mediasApi.medias + `/${parameter.id}/${parameter.name}`,
    data: parameter
  })
}

export function editInMedias (parameter) {
  return request({
    url: mediasApi.medias + `/${parameter.id}/${parameter.name}`,
    method: 'patch'
  })
}

export function findByHousesIdMedias (parameter) {
  return request({
    url: mediasApi.findByHouses_Id + `?houseId=${parameter.id}&page=${parameter.page}&size=${parameter.size}&sort=${parameter.sort}`,
    method: 'get'
  })
}
