<template>
  <a-card :bordered="false">
    <div class="table-page-search-wrapper">
      <a-form layout="inline">
        <a-row :gutter="48">
          <a-col :md="8" :sm="24">
            <a-form-item label="搜索">
              <a-input v-model="queryParam.keyword" placeholder="请输入搜索信息" />
            </a-form-item>
          </a-col>
          <a-col :md="(!advanced && 8) || 24" :sm="24">
            <span
              class="table-page-search-submitButtons"
              :style="(advanced && { float: 'right', overflow: 'hidden' }) || {}"
            >
              <a-button type="primary" @click="findByKeyword">查询</a-button>
              <a-button style="margin-left: 8px" @click="tableReset">重置</a-button>
            </span>
          </a-col>
        </a-row>
      </a-form>
    </div>

    <div class="table-operator">
      <a-button type="primary" icon="plus" @click="handleAdd">新增</a-button>
      <a-button type="danger" icon="delete" @click="handleDelete">删除</a-button>
    </div>
    <a-modal
      title="刪除"
      :visible="del.visible"
      :confirm-loading="del.confirmLoading"
      @ok="handleDeleteOk"
      @cancel="handleDeleteCancel"
    >
      <p>{{ del.ModalText }}</p>
    </a-modal>

    <s-table
      ref="table"
      size="default"
      :rowKey="record => record.id"
      :columns="columns"
      :data="newsData"
      :alert="true"
      :rowSelection="rowSelection"
      showPagination="auto"
    >
      <span slot="content" slot-scope="text">
        <ellipsis :length="150" tooltip>{{ text }}</ellipsis>
      </span>

      <span slot="action" slot-scope="text, record">
        <template>
          <a @click="handleEdit(record)">编辑</a>
        </template>
      </span>
    </s-table>

    <create-form
      ref="createModal"
      :visible="visible"
      :loading="confirmLoading"
      :model="mdl"
      :media="media"
      @cancel="handleCancel"
      @ok="handleOk"
      @emitMedia="getMedia"
    />
    <step-by-step-modal ref="modal" @ok="handleOk" />
  </a-card>
</template>

<script>
import { STable, Ellipsis } from '@/components'
import { addNews, delNews, findNews, editNews, findByHousesIdNews, putNewsMedia } from '@/api/news'
// import { houseSub } from '@/api/house'
import { uploadOssBatch } from '@/utils/upload'

import StepByStepModal from './modules/StepByStepModal'
import CreateForm from './modules/CreateForm'

import { THUMBNAIL_MAX, THUMBNAIL_VEDIOCOVER } from '@/common/const'

const columns = [
  {
    title: '分类标签',
    dataIndex: 'category'
  },
  {
    title: '内容',
    dataIndex: 'content',
    scopedSlots: { customRender: 'content' }
  },
  {
    title: '发布人',
    dataIndex: 'user.name'
  },
  {
    title: '发布时间',
    dataIndex: 'createTime',
    sorter: true
  },
  {
    title: '操作',
    dataIndex: 'action',
    width: '100px',
    scopedSlots: { customRender: 'action' }
  }
]

const statusMap = {
  0: {
    status: 'default',
    text: '关闭'
  },
  1: {
    status: 'processing',
    text: '运行中'
  },
  2: {
    status: 'success',
    text: '已上线'
  },
  3: {
    status: 'error',
    text: '异常'
  }
}

export default {
  name: 'TableList',
  components: {
    STable,
    Ellipsis,
    CreateForm,
    StepByStepModal
  },
  data() {
    this.columns = columns
    return {
      houseId: '',
      houseLink: '',
      // create model
      visible: false,
      confirmLoading: false,
      mdl: null,
      media: [],
      // 批量删除 modal
      del: {
        ModalText: '您要删除这些项目吗',
        visible: false,
        confirmLoading: false
      },
      // 高级搜索 展开/关闭
      advanced: false,
      // 查询参数
      queryParam: {
        keyword: ''
      },
      search: {
        visible: false
      },
      // 加载数据方法 必须为 Promise 对象
      newsData: parameter => {
        console.log(parameter, 'parameter')
        let sorterStr = ''
        if (parameter.sortOrder === 'descend') {
          sorterStr = `${parameter.sortField},desc`
        } else if (parameter.sortOrder === 'ascend') {
          sorterStr = `${parameter.sortField}`
        } else {
          sorterStr = `createTime,desc`
        }
        const requestParameters = {
          id: this.houseId,
          page: parameter.pageNo - 1,
          size: parameter.pageSize,
          sort: sorterStr
        }
        console.log(requestParameters, 'requestParameters')
        return findByHousesIdNews(requestParameters)
          .then(res => {
            const result = {
              data: res._embedded.news,
              pageNo: res.page.number,
              pageSize: res.page.size,
              totalCount: res.page.totalElements,
              totalPages: res.page.totalPages
            }
            console.log(result, 'result')
            // return result
            if (this.search.visible) {
              console.log('search')
              requestParameters.keyword = this.queryParam.keyword
              return findNews(requestParameters)
                .then(res => {
                  console.log(res, 'search')
                  const findResult = {
                    data: res._embedded.news,
                    pageNo: res.page.number,
                    pageSize: res.page.size,
                    totalCount: res.page.totalElements,
                    totalPages: res.page.totalPages
                  }
                  return findResult
                })
            } else {
              // result = result.data.map(item => {
              //   getNewsMedia(item.id).then(res1 => {
              //     item.media = res1._embedded.medias
              //   })
              // })
              return result
            }
          })
      },
      selectedRowKeys: [],
      selectedRows: []
    }
  },
  filters: {
    statusFilter(type) {
      return statusMap[type].text
    },
    statusTypeFilter(type) {
      return statusMap[type].status
    }
  },
  created() {
    this.houseId = this.$route.query.id
    this.houseLink = this.$route.query.link
  },
  computed: {
    rowSelection() {
      return {
        selectedRowKeys: this.selectedRowKeys,
        onChange: this.onSelectChange
      }
    },
    host() {
      return this.$store.state.oss.host
    },
    serial() {
      return this.$store.state.env.serial
    },
    userId() {
      return this.$store.state.user.info.id
    }
  },
  methods: {
    getMedia(data) {
      console.log(data, 'news data')
      this.media = data
    },
    handleAdd() {
      this.mdl = null
      this.media = []
      this.visible = true
    },
    handleEdit(record) {
      console.log(this.$store.state.user, 'user')
      this.visible = true
      if (record.media) {
        console.log(record, 'resv')
        const fileList = record.media.map(item => {
          console.log(item, 'filelist item')
          const file = {
            uid: item.id,
            name: item.path.slice(item.path.lastIndexOf('/') + 1),
            status: 'done',
            path: item.path,
            sort: item.sort
          }
          const path = `${this.host}${item.path}`
          if (item.type === 'video/mp4') {
            file.url = `${path}${THUMBNAIL_VEDIOCOVER}`
            file.src = path
          } else {
            file.url = `${path}${THUMBNAIL_MAX}`
          }
          return file
        })
        this.media = fileList
      }
      this.mdl = { ...record }
    },
    // 批量删除
    handleDelete() {
      const len = this.selectedRows.length
      if (len <= 0) {
        this.$message.warning('请选择要删除的项', 2)
      } else {
        this.del.visible = true
      }
    },
    handleDeleteOk(e) {
      this.del.ModalText = '将在两秒后删除此项'
      this.del.confirmLoading = true
      setTimeout(() => {
        this.del.confirmLoading = false
        this.del.ModalText = '您要删除这些项目吗'
        this.confirmLoading = true
        if (this.del.visible !== false) {
          this.selectedRows.forEach((item, index) => {
            const requestParameters = {
              id: item.id
            }
            delNews(requestParameters)
              .then(res => {
                // 刷新表格
                this.$refs.table.refresh()
                this.$message.success('删除成功', 2)
                this.del.visible = false
                this.confirmLoading = false
                this.selectedRows.splice(0, this.selectedRows.length)
              })
              .catch(() => {
                this.confirmLoading = false
              })
          })
        } else {
          this.del.visible = false
          this.confirmLoading = false
        }
      }, 2000)
    },
    handleDeleteCancel(e) {
      
      this.del.visible = false
    },
    handleOk() {
      const form = this.$refs.createModal.form
      this.confirmLoading = true
      form.validateFields((errors, values) => {
        if (!errors) {
          const params = { ...values }
          params.user = this.serial + '/rest/users/' + this.userId
          const { media, ...requestParameters } = params
          if (this.mdl) {
            requestParameters.id = this.mdl.id
            editNews(requestParameters)
              .then(res => {
                const msg = '编辑成功'
                if (this.media.length === 0) {
                  this.visible = false
                  this.confirmLoading = false
                  // 重置表单数据
                  form.resetFields()
                  // 刷新表格
                  this.$refs.table.refresh()
                  this.$message.success(msg)
                } else {
                  this.fileListUpload(requestParameters.id, this.media, msg)
                }
              })
              .catch(() => {
                this.confirmLoading = false
              })
          } else {
            requestParameters.house = this.houseLink
            console.log(requestParameters, 'requestParameters')
            addNews(requestParameters)
              .then(res => {
                console.log(this.mdl, 'this.mdl add')
                if (this.media.length === 0) {
                  const msg = '新建成功'
                  this.visible = false
                  this.confirmLoading = false
                  // 重置表单数据
                  form.resetFields()
                  // 刷新表格
                  this.$refs.table.refresh()
                  this.$message.success(msg)
                } else {
                  this.fileListUpload(res.id, this.media)
                }
              })
              .catch(() => {
                this.confirmLoading = false
              })
          }
        } else {
          this.confirmLoading = false
        }
      })
    },
    fileListUpload(id, fileList, msg = '新建成功') {
      const form = this.$refs.createModal.form
      const mediaList = fileList.map(item => {
        if (item.originFileObj === undefined) {
          return item
        } else {
          return item.originFileObj
        }
      })
      uploadOssBatch(mediaList, '/news/img/' + id).then(mediaRes => {
        if (mediaRes) {
          console.log(mediaRes, 'mediaRes')
          const urls = mediaRes
            .map(item => {
              if (item._links) {
                return item._links.self.href
              } else {
                return this.serial + '/rest/medias/' + item.uid
              }
            })
            .join('\n')
          console.log(urls, 'urls')
          putNewsMedia(id, urls).then(res2 => {
            this.visible = false
            this.confirmLoading = false
            // 重置表单数据
            form.resetFields()
            // 刷新表格
            this.$refs.table.refresh()
            this.$message.success(msg)
          })
        }
      })
    },
    handleCancel() {
      this.visible = false
      const form = this.$refs.createModal.form
      form.resetFields() // 清理表单数据（可不做）
      this.mdl = null
    },
    onSelectChange(selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys
      this.selectedRows = selectedRows
    },
    findByKeyword() {
      this.search.visible = true
      this.$refs.table.refresh()
    },
    tableReset() {
      this.search.visible = false
      this.queryParam = {
        keyword: ''
      }
      this.$refs.table.refresh()
    }
  }
}
</script>
