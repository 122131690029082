import { sign, upload } from '@/api/oss'
import { saveMedias, editMedias } from '@/api/medias'

export function getSign() {
  return sign().then(res => {
    return res.data
  }).catch(err => {
    console.log(err, 'sign')
  })
}

/**
 * 
 * @param 'developer/contract/' path 
 * @param file file 
 */
export function upali(key, file) {
  return getSign().then(res => {
    const host = res.host
    const parameters = new FormData()
    parameters.append('key', `${key}`)
    // parameters.append('key', `${key}?x-oss-process=image/resize,w_300`)
    parameters.append('OSSAccessKeyId', res.accessKeyId)
    parameters.append('policy', res.policy)
    parameters.append('Signature', res.signature)
    parameters.append('file', file)
    return upload(host, parameters).then(upRes => {
      return upRes
    }).catch(err => {
      console.log(err, 'upload err')
      return false
    })
  }).catch(err => {
    console.log(err, 'getSign err')
  })
}

/**
 * 
 * @param 'developer/contract/' path 
 * @param file file 
 */
export function uploadAli(auth, arr, prefix, sort, index = 0, saved = [], type = 'image/jpeg') {
  console.log(arr, 'arr');
  console.log(arr[index], 'item');
  console.log(index, 'item');
  console.log(auth, 'auth');
  if (arr.length === 0) {
    return saved
  }
  if (arr[index].url === undefined) {
    type = arr[index].type
    const file = arr[index]
    const host = auth.host

    const path = arr[index].name;
    prefix = (prefix.startsWith('/') ? prefix.substring(1) : prefix)
    const key = prefix + '/' + path;
    const parameters = new FormData()
    parameters.append('key', `${key}`)
    parameters.append('OSSAccessKeyId', auth.accessKeyId)
    parameters.append('policy', auth.policy)
    parameters.append('Signature', auth.signature)
    parameters.append('file', file)

    console.log(host, parameters);
    return upload(host, parameters).then(res => {
      const saveParams = {
        type,
        path: '/' + key,
        sort: index
      }
      return saveMedias(saveParams).then(res2 => {
        saved.push(res2)
        console.log(arr, 'save arr');
        if (arr.length === saved.length) {
          console.log(saved, 'saved arr');
          return saved
        } else {
          return uploadAli(auth, arr, prefix, sort, index + 1, saved)
        }
      })
    }).catch(err => {
      console.log(err, 'upload err')
      return false
    })
  } else {
    console.log('else');
    if (sort) {
      const id = arr[index].uid
      const params = {
        sort: index
      }
      return editMedias(id, params).then(res => {
        if (arr.length === saved.length) {
          return saved
        } else {
          saved.push(arr[index])
          if (arr.length === saved.length) {
            return saved
          } else {
            return uploadAli(auth, arr, prefix, sort, index + 1, saved)
          }
        }
      })
    } else {
      if (arr.length === saved.length) {
        return saved
      } else {
        saved.push(arr[index])
        if (arr.length === saved.length) {
          return saved
        } else {
          return uploadAli(auth, arr, prefix, sort, index + 1, saved)
        }
      }
    }
  }
}

// OSS 批量上传
export function uploadOssBatch(arr = [], prefix = 'upload', sort = false) {
  return sign().then(res => {
    if (res) {
      return uploadAli(res.data, arr, prefix, sort)
    } else {
      console.log('获取host失败');
    }
  }).catch(err => {
    console.log(err, 'sign')
  })
}
